require('./bootstrap');
require('lightgallery/dist/js/lightgallery-all.js');

$(document).ready(function() {

var touchHover = function() {
    $('[data-hover]').click(function(e){
        e.preventDefault();
        var $this = $(this);
        var onHover = $this.attr('data-hover');
        var linkHref = $this.attr('href');
        if (linkHref && $this.hasClass(onHover)) {
            location.href = linkHref;
            return false;
        }
        $this.toggleClass(onHover);
    });
};


			$("#lightgallery").lightGallery({
				thumbnail : true
			}); 



			touchHover(); 
		});